import {Link} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import WA from '../../assets/images/wa.svg'
import TELEGRAM from '../../assets/images/telegram.svg'
import {AppRoutes} from "../../router/routeConfig";

export default function Footer() {
   const {t} = useTranslation()


   const services = [
      {name: t('footer.services.pack'), href: AppRoutes.SERVICE + '#package'},
      {name: t('footer.services.busines'), href: AppRoutes.SERVICE + '#busines'},
      {name: t('footer.services.meet'), href: AppRoutes.SERVICE + '#meet'},
      {name: t('footer.services.firstline'), href: AppRoutes.SERVICE + '#firstline'},
   ]
   const servicesRender = services.map((item) => {
      return (
         <li key={item.name}>
            <Link to={item.href}>{item.name}</Link>
         </li>
      )
   })

   return (
      <footer>
         <div className='container'>
            <div className='row'>
               <div className='col-lg-3 col-12'>
                  <div className='row footer-contacts d-flex justify-content-between'>
                     <div className='col-lg-12 col-8'>
                        <h3>{t('footer.connect')}</h3>
                        <p>
                           E-mail:&nbsp;
                           <span>
                              <a href='mailto:support@mileonair.com'>
                                 support@mileonair.com
                              </a>
                           </span>
                        </p>
                        <p>
                           {t('footer.phone')}&nbsp;
                           <span>
                              <a href='tel:88003501024'>
                                 8 800 350 10 24
                              </a>
                           </span>
                        </p>
                     </div>
                     <div
                        className='col-lg-12 col-3 mt-3 d-flex social-links'
                        style={{gap: '16px', alignItems: 'flex-end'}}
                     >
                        <span>
                           <a href='https://t.me/mileonair_hotline' target='_blank' rel="noreferrer">
                              <img alt="telegram contact" src={TELEGRAM}/>
                           </a>
                        </span>
                        <span>
                           <a href='https://wa.me/79857395956' target='_blank' rel="noreferrer">
                              <img alt="whatsup contact" src={WA}/>
                           </a>
                        </span>
                     </div>
                  </div>
               </div>

               <div className='col-lg-2 col-6'>
                  <h3>{t('footer.services.title')}</h3>
                  <ul>
                     {servicesRender}
                  </ul>
               </div>

               <div className='col-lg-2 col-6'>
                  <h3>{t('footer.menu')}</h3>
                  <ul>
                     <li>
                        <Link to='/partners'>{t('footer.partners')}</Link>
                     </li>
                  </ul>
               </div>

               <div className='col-lg-4 col-12'>
                  <p className='copy'>
                     &copy;<Link to='/'>{t('footer.copyright.part1')}</Link>,<br></br>
                     {t('footer.copyright.part2')}
                     <br></br>
                     {t('footer.copyright.part3')}
                     <br></br>
                     {t('footer.copyright.part4')}
                     <br></br>
                     {t('footer.copyright.part5')}
                  </p>
               </div>
            </div>

            <div
               className='up'
               onClick={() => window.scrollTo(0, 0)}
            >
               {t('footer.up')}
            </div>
         </div>
      </footer>
   )
}
