import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { backendUrl } from '../../constants'

export const fetchPayment = (firstBody, secondBody) => new Promise(async (resolve, reject) => {
   const responseRegisterOrder = await fetch(
      `${backendUrl}/v2/UserCartRegisterOrder`,
      {
         method: 'POST',
         credentials: 'include',
         body: firstBody
      }
   )
   const resultRegisterOrder = await responseRegisterOrder.json()

   if (resultRegisterOrder.responseCode === 0) {
      const responsePayWeb = await fetch(
         `${backendUrl}/v2/payWeb`,
         {
            method: 'POST',
            credentials: 'include',
            body: secondBody
         }
      )

      const resultPayWeb = await responsePayWeb.json()
      if (resultPayWeb.responseCode === 0) {
         resolve(resultPayWeb)
      }
   }

   reject()
})

export const fetchUserCart = createAsyncThunk(
   'cart/fetchUserCart',
   async (lng, { signal }) => {
      const responseCart = await fetch(
         `${backendUrl}/v2/userCart?language_code=${lng}`,
         {
            method: 'GET',
            credentials: 'include',
            signal
         }
      )
      const resultCart = await responseCart.json()
      return resultCart.data
   }
)

export const fetchDeleteItemFromBucket = createAsyncThunk(
   'cart/fetchDeleteItemFromBucket',
   async ({ group_id }, { signal }) => {
      const responseDeleteItemFromBucket = await fetch(
         `${backendUrl}/userCart/itemGroup`,
         {
            method: 'DELETE',
            credentials: 'include',
            body: JSON.stringify({ group_id }),
            signal
         }
      )
      return {
         group_id,
         response: await responseDeleteItemFromBucket.json()
      }
   }
)

export const fetchClearBucket = createAsyncThunk(
   'cart/fetchClearBucket',
   async (_, { signal }) => {
      const responseClearBucket = await fetch(
         `${backendUrl}/v2/userCart`,
         {
            method: 'DELETE',
            credentials: 'include',
            signal
         }
      )
      const resultClearBucket = await responseClearBucket.json()
      return resultClearBucket
   }
)

const initialState = {
   cart: {},
   isLoading: false
}

const cartSlice = createSlice({
   name: 'cart',
   initialState,
   reducers: {
      clearCart: () => initialState
   },
   extraReducers: {
      [fetchUserCart.fulfilled]: (state, { payload }) => {
         state.cart = payload
         state.isLoading = false
      },
      [fetchUserCart.pending]: (state) => {
         state.isLoading = true
      },
      [fetchClearBucket.fulfilled]: (_, { payload }) => {
         if (payload.responseCode === 0) return initialState
      },
      [fetchDeleteItemFromBucket.fulfilled]: (state, { payload }) => {
         if (payload.response.responseCode === 0) {
            state.cart.groups = state.cart.groups.filter(g => g.id !== payload.group_id)
         }
      }
   }
})
export const { clearCart } = cartSlice.actions
export default cartSlice.reducer
