import {
   combineReducers,
   configureStore
} from '@reduxjs/toolkit'
import {
   persistReducer,
   persistStore,
   FLUSH,
   REHYDRATE,
   PAUSE,
   PERSIST,
   PURGE,
   REGISTER
} from 'reduxjs-toolkit-persist'
import storageSession from 'reduxjs-toolkit-persist/lib/storage/session'
import autoMergeLevel1 from 'reduxjs-toolkit-persist/lib/stateReconciler/autoMergeLevel1'

import airportsSlice from './slices/airportsSlice'
import searchSlice from './slices/searchSlice'
import availableServicesSlice from './slices/availableServicesSlice'
import selectedServicesSlice from './slices/selectedServicesSlice'
import tabsSlice from './slices/tabsSlice'
import userSlice from './slices/userSlice'
import privilegesSlice from './slices/privilegesSlice'
import cartSlice from './slices/cartSlice'

const persistConfig = {
   key: 'root',
   storage: storageSession,
   stateReconciler: autoMergeLevel1,
   blacklist: ['user', 'loading', 'cart']
}

const reducers = combineReducers({
   airports: airportsSlice,
   search: searchSlice,
   awialibleServices: availableServicesSlice,
   selectedServices: selectedServicesSlice,
   tabs: tabsSlice,
   user: userSlice,
   cart: cartSlice,
   privileges: privilegesSlice
})
const _persistedReducer = persistReducer(persistConfig, reducers)
export const storePersist = configureStore({
   reducer: _persistedReducer,
   middleware: (getDefaultMiddleware) => getDefaultMiddleware({
      serializableCheck: {
         ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
      }
   }),
   devTools: process.env.NODE_ENV !== 'production'
})
export const persistor = persistStore(storePersist)
