import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import DatePickerDialog from './day-picker/dayPickerDialog'
import SelectGroup from './select-group/SelectGroup'

import {
   setDateFrom,
   setDateTo,
   switchAirpots,
   setAirportFrom,
   setAirportTo
} from '../../redux/slices/searchSlice'

export default function Search () {
   const search = useSelector((state) => state.search)
   const dispatch = useDispatch()
   const navigate = useNavigate()
   const { t } = useTranslation()

   const [showErrors, setShowErrors] = useState(false)
   const errorFrom = showErrors && !search.from.airport
   const errorDate = showErrors && !search.dateFrom
   useEffect(() => setShowErrors(false), [search])

   useEffect(() => {
      const urlSearchParams = new URLSearchParams(window.location.search)
      const params = Object.fromEntries(urlSearchParams.entries())

      setTimeout(() => {
         if (params.arrival) dispatch(setAirportFrom(params.arrival))
         if (params.destination) dispatch(setAirportTo(params.destination))

         const today = moment()

         const arrivalDate = moment(params.arrival_date || null)
         if (arrivalDate.isValid() &&
        arrivalDate.isSameOrAfter(today)) dispatch(setDateFrom(arrivalDate.format('YYYY-MM-DD')))

         const destDate = moment(params.dest_date || null)
         if (destDate.isValid() &&
          destDate.isSameOrAfter(today) &&
          (!arrivalDate.isValid() || destDate.isSameOrAfter(arrivalDate))) {
            dispatch(setDateTo(destDate.format('YYYY-MM-DD')))
         }
      })
   }, [])

   return (
      <section className='main_panel'>
         <div className='container'>
            <div className='row'>
               <div className='col-12'>
                  <div className='panel'>
                     <div className='airport'>
                        <SelectGroup direction={'from'} />
                        <span className={errorFrom ? 'from_error_active' : 'from_error'}>
                           {t('searchbar.required')}
                        </span>
                        <SelectGroup direction={'to'} />

                        <div
                           className='change'
                           onClick={() => dispatch(switchAirpots())}
                        ></div>
                     </div>

                     <div className='date'>
                        <DatePickerDialog search={search} direct={'from'}/>
                        <span className={errorDate ? 'date_error_active' : 'date_error'}>
                           {t('searchbar.required')}
                        </span>
                        <DatePickerDialog search={search} direct={'to'}/>
                     </div>

                     <div
                        className='btn'
                        onClick={() => (search.from.airport !== null && search.dateFrom !== null)
                           ? navigate('/services')
                           : setShowErrors(true)}
                     >
                        {t('searchbar.find')}
                     </div>

                     <span className='under'></span>
                  </div>
               </div>
            </div>
         </div>
      </section>
   )
}
