import './loader.scss'
export default function Loader () {
   return (
      <div className='lds-roller'>
         <div></div>
         <div></div>
         <div></div>
         <div></div>
      </div>
   )
}
