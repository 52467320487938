import { useEffect, useRef, forwardRef, useImperativeHandle, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { setAirportFrom, setAirportTo } from '../../../redux/slices/searchSlice'

const SelectItemList = forwardRef(({ direction, value, setValue }, ref) => {
   const airports = useSelector((state) => state.airports)
   const { from, to } = useSelector((state) => state.search)

   const [cursor, setCursor] = useState(0)
   const itemRef = useRef(null)
   const dispatch = useDispatch()

   const data = airports.filter(a => !(a.code_iata === from.airport || a.code_iata === to.airport) &&
                                    a?.verbalName.toLowerCase().includes(value.toLowerCase()))

   const pointCount = (data.length - 1)

   const selectAirport = direction === 'from'
      ? (item) => dispatch(setAirportFrom(item || data[cursor]))
      : (item) => dispatch(setAirportTo(item || data[cursor]))
   const setAirport = (item) => { selectAirport(item); setValue('') }

   useImperativeHandle(ref, () => ({
      cursorUp: () => cursor > 0 && setCursor(v => v - 1),
      cursorDown: () => cursor < pointCount && setCursor(v => v + 1),
      setAirport
   }))

   useEffect(() => itemRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'start'
   }), [cursor])

   return <ul>{data.map((item, i) =>
      <li
         key={item.id}
         style={{ backgroundColor: cursor === i ? '#ebebeb' : null }}
         onClick={() => setAirport(item)}
         ref={cursor === i ? itemRef : null}
      >
         {item.verbalName}
      </li>)
   }</ul>
})

export default SelectItemList
