import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { setDefaultAvailableServices } from '../../redux/slices/availableServicesSlice'
import { setDefaultSelectedServices } from '../../redux/slices/selectedServicesSlice'
import HeaderCarousel from '../../components/carousel/Carousel'
import MapWithContent from '../../components/map-with-content/MapWithContent'
import Search from '../../components/search/Search'

export default function Home () {
   const dispatch = useDispatch()

   useEffect(() => {
      dispatch(setDefaultSelectedServices())
      dispatch(setDefaultAvailableServices())
   }, [])

   return (
      <>
         <HeaderCarousel />
         <Search />
         <MapWithContent />
      </>
   )
}
