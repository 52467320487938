
export const deviceInfo = {
   ip_address: '10.86.8.4',
   push_permission: 'denied',
   geoAccuracyPermission: 'fullAccuracy',
   device_model: 'iPhone 11 Pro Max',
   os: 'iOS',
   fcm_token:
   // eslint-disable-next-line
    'cuZTTWYXk0hntCE4OY-vh8:APA91bGgVSxyz7JwhPdEiOVqDZyis_a263ooyj_vPVe7rjsr5rXWSWpGJSHxuQAWypUE5_UXd0fONE1Zi63DB0zF0GtJXXqrwb4h8HSKupnZiAsT-ZG6ridpjBoH3d28XqIIVwFeBWhJ',
   app_version: '1.3.5',
   instance_id: 'cuZTTWYXk0hntCE4OY-vh8',
   os_version: '14.7.1',
   geo_permission: 'notDetermined'
}
