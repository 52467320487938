import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { backendUrl } from '../../constants'

const initialState = {
   from: {
      airport: null,
      terminal: null
   },
   to: {
      airport: null,
      terminal: null
   }
}

const fetchAvailableServices = (typePrefix) => createAsyncThunk(
   typePrefix,
   async ({ airport, lng }, { signal }) => {
      if (!airport.airport) return null

      const response = await fetch(
         // eslint-disable-next-line
         `${backendUrl}/serviceCategories?airport_code=${airport.airport}&language_code=${lng}&terminal=${airport?.terminal || ''}`,
         {
            signal
         }
      )
      const result = await response.json()

      const { categories } = result.data
      return { ...airport, services: categories }
   }
)

export const fetchAvailableServicesFrom = fetchAvailableServices('availableServicesSlice/fetchAvailableServicesFrom')

export const fetchAvailableServicesTo = fetchAvailableServices('availableServicesSlice/fetchAvailableServicesTo')

const availableServicesSlice = createSlice({
   name: 'availableServices',
   initialState,

   reducers: {
      setDefaultAvailableServices: () => initialState,

      clearFrom: (state) => ({
         ...state,
         from: {
            airport: null,
            terminal: null
         }
      }),

      clearTo: (state) => ({
         ...state,
         to: {
            airport: null,
            terminal: null
         }
      })
   },

   extraReducers: {
      [fetchAvailableServicesFrom.fulfilled]: (state, action) => {
         state.from = action.payload
      },

      [fetchAvailableServicesTo.fulfilled]: (state, action) => {
         state.to = action.payload
      }
   }
})
export const { setDefaultAvailableServices, clearFrom, clearTo } = availableServicesSlice.actions
export default availableServicesSlice.reducer
