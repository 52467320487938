import { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { DayPicker } from 'react-day-picker'
import ru from 'date-fns/locale/ru'
import en from 'date-fns/locale/en-US'
import moment from 'moment'
import {
   setDateFrom,
   setDateTo
} from '../../../redux/slices/searchSlice'

import dateImg from '../../../assets/images/date.svg'
import 'react-day-picker/dist/style.css'
import './date.scss'

export default function DatePickerDialog ({ search, direct }) {
   const [selected, setSelected] = useState('')
   const [isPopperOpen, setIsPopperOpen] = useState(false)
   const { t, i18n } = useTranslation()
   const ref = useRef(null)
   const dispatch = useDispatch()

   const closeSelect = () => setIsPopperOpen(false)

   const convertDate = (date, format) => {
      return moment(date).format(format)
   }

   useEffect(() => {
      if (isPopperOpen) {
         window.addEventListener('click', (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
               closeSelect()
            }
         })
      } else {
         window.removeEventListener('click', closeSelect)
      }

      return () => {
         window.removeEventListener('click', closeSelect)
      }
   }, [isPopperOpen])

   const handleDateSelect = (date) => {
      setSelected(date)
      closeSelect()
      if (direct === 'from') {
         dispatch(setDateFrom(convertDate(date, 'YYYY-MM-DD')))
      } else {
         dispatch(setDateTo(convertDate(date, 'YYYY-MM-DD')))
      }
   }

   return (
      <div
         ref={ref}
      >
         <input
            readOnly
            type="text"
            className="depart"
            placeholder={direct === 'from' ? t('searchbar.date_from') : t('searchbar.date_to')}
            value={direct === 'from'
               ? (search.dateFrom ? convertDate(search.dateFrom, 'DD.MM.YY') : '')
               : (search.dateTo ? convertDate(search.dateTo, 'DD.MM.YY') : '')}
            onClick={() => setIsPopperOpen(!isPopperOpen)}
            style = {{
               background: direct === 'from'
                  ? `url(${dateImg}) center right 12px no-repeat, 
                      rgba(255, 255, 255, ${search.dateFrom ? '0.85' : '0.44'})`
                  : `url(${dateImg}) center right 12px no-repeat, 
                      rgba(255, 255, 255, ${search.dateTo ? '0.85' : '0.44'})`,
               color: '#434343'
            }}
         >
         </input>
         {isPopperOpen && (
            <DayPicker
               className={direct}
               locale={i18n.language === 'ru' ? ru : en}
               mode="single"
               required
               onSelect={handleDateSelect}
               disabled={{
                  before: direct === 'from'
                     ? new Date().setHours(0, 0, 0, 0)
                     : (search.dateFrom ? new Date(search.dateFrom) : new Date()),
                  after: direct === 'from'
                     ? (search.dateTo ? new Date(search.dateTo) : null)
                     : null
               }}
               selected={selected}
            />
         )}
      </div>
   )
}
