import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { Provider } from 'react-redux';
import { PersistGate } from 'reduxjs-toolkit-persist/integration/react';
import { persistor, storePersist } from './redux/store-persist';
import './i18n';
import LoaderMain from './components/loader/main/LoaderMain';

ReactDOM.render(
   <React.StrictMode>
      <Provider store={ storePersist }>
         <Suspense fallback={ <div></div> }>
            <PersistGate loading={ <LoaderMain/> } persistor={ persistor }>
               <App/>
            </PersistGate>
         </Suspense>
      </Provider>
   </React.StrictMode>,
   document.getElementById('root'),
);
