import React, {useEffect} from 'react';
import './LoaderMain.scss';
import cloud1 from '../../../assets/images/loader/cloud1.svg';
import cloud2 from '../../../assets/images/loader/cloud2.svg';
import plane from '../../../assets/images/loader/plane.svg';

const LoaderMain = () => {

   useEffect(() => {
      const meta = document.createElement('meta');
      meta.name = 'viewport';
      meta.content = 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0';
      document.getElementsByTagName('head')[0].appendChild(meta);
      return () => {
         document.getElementsByTagName('head')[0].removeChild(meta);
      }
   }, [])

   return (
      <div className={'main-loader'}>
         <div className="content">
            <div className="cloud cloud-1">
               <img alt={'cloud'} src={cloud1}/>
            </div>
            <div className="cloud cloud-2">
               <img alt={'cloud'} src={cloud2}/>
            </div>
            <div className="plane">
               <img alt={'plane'} src={plane}/>
            </div>
         </div>
      </div>
   );
};

export default LoaderMain;
