import { lazy } from 'react'

export const Login = lazy(async () => await import('./login/Login'))
export const WithoutRegistration = lazy(async () => await import('./login/WithoutRegistration'))
export const Profile = lazy(async () => await import('./profile/Profile'))
export const Partners = lazy(async () => await import('./partners/Partners'))
export const FillProfile = lazy(async () => await import('./profile/FillProfile'))
export const Services = lazy(async () => await import('./services/Services'))
export const Service = lazy(async () => await import('./services_description/Service'))
export const Points = lazy(async () => await import('./points/Points'))
export const Cart = lazy(async () => await import('./cart/Cart'))
export const Success = lazy(async () => await import('./success/Success'))
export const NotFound = lazy(async () => await import('./not_found/NotFound'))
