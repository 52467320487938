import { createSlice } from '@reduxjs/toolkit'

const initialState = {
   from: {
      dateFrom: [],
      dateTo: []
   },
   to: {
      dateFrom: [],
      dateTo: []
   }
}

const selectedServicesSlice = createSlice({
   name: 'selectedServices',
   initialState,
   reducers: {
      setSelectedServices: (state, { payload }) => {
         if (payload.direction === 'from') {
            if (payload.date === 'from') {
               state.from.dateFrom.push(payload.item)
            } else {
               state.from.dateTo.push(payload.item)
            }
         } else {
            if (payload.date === 'from') {
               state.to.dateFrom.push(payload.item)
            } else {
               state.to.dateTo.push(payload.item)
            }
         }
      },

      removeSelectedServices: (state, { payload }) => {
         if (payload.direction === 'from') {
            if (payload.date === 'from') {
               state.from.dateFrom = state.from.dateFrom.filter(i => i !== payload.item)
            } else {
               state.from.dateTo = state.from.dateTo.filter(i => i !== payload.item)
            }
         } else {
            if (payload.date === 'from') {
               state.to.dateFrom = state.to.dateFrom.filter(i => i !== payload.item)
            } else {
               state.to.dateTo = state.to.dateTo.filter(i => i !== payload.item)
            }
         }
      },

      setDefaultSelectedServices: () => initialState
   }
})

export const {
   setSelectedServices,
   removeSelectedServices,
   setDefaultSelectedServices
} = selectedServicesSlice.actions
export default selectedServicesSlice.reducer
