import { createSlice } from '@reduxjs/toolkit'

const initialState = {
   from: {
      airport: null,
      terminal: null
   },
   to: {
      airport: null,
      terminal: null
   },
   dateFrom: null,
   dateTo: null
}

const searchSlice = createSlice({
   name: 'search',
   initialState,
   reducers: {
      setAirportTo: (state, { payload }) => {
         if (payload?.code_iata !== state.from.airport) {
            return {
               ...state,
               to: {
                  airport: payload?.code_iata || null,
                  terminal: null
               }
            }
         }
      },

      setAirportFrom: (state, { payload }) => {
         if (payload?.code_iata !== state.to.airport) {
            return {
               ...state,
               from: {
                  airport: payload?.code_iata || null,
                  terminal: null
               }
            }
         }
      },

      switchAirpots: (state) => {
         const from = state.to
         const to = state.from
         state.from = from
         state.to = to
      },

      setDateFrom: (state, action) => {
         state.dateFrom = action.payload
      },

      setDateTo: (state, action) => {
         state.dateTo = action.payload
      },

      setDefaultSearchSlice: () => initialState
   }
})

export const {
   setAirportFrom,
   setAirportTo,
   switchAirpots,
   setDateFrom,
   setDateTo,
   setDefaultSearchSlice
} = searchSlice.actions
export default searchSlice.reducer
