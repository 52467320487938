import { useTranslation } from 'react-i18next'
import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { setAirportFrom, setAirportTo } from '../../redux/slices/searchSlice'

export default function MapWithContent () {
   const { t } = useTranslation()
   const dispatch = useDispatch()
   const airports = useSelector((state) => state.airports)
   const [searchToSet, toggleSearchToSet] = useState(false)

   const setAirport = (name) => {
      const airport = airports.find((el) => el.city === name || el.title === name)
      if (!searchToSet) dispatch(setAirportFrom(airport))
      else dispatch(setAirportTo(airport))
      toggleSearchToSet(v => !v)
   }

   const airportsList = Array.from(new Array(15), (x, i) => i + 1).map((number) => {
      const airportTitle = t(`content.airports.${number}.title`)
      const airportName = t(`content.airports.${number}.name`)

      return <div
         className='airport'
         onClick={(event) => {
            event.stopPropagation()
            setAirport(airportTitle)
         }}
         key={airportTitle}
      >
         <h3>{airportTitle}</h3>
         <ul>
            <li>{airportName}</li>
         </ul>
      </div>
   })

   return (
      <main>
         <section className='airports_list'>
            <div className='container'>
               <div className='row'>
                  <div className='col-12'>
                     <h2>{t('content.title')}</h2>
                     <div className='airports'>
                        {airportsList}
                     </div>
                  </div>
               </div>
            </div>
         </section>
      </main>
   )
}
