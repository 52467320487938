import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { backendUrl } from '../../constants'

const initialState = {
   tabs: [],
   tabsPoints: {},
   selectedTab: null,
   selectedPoint: null,
   isLoading: false
}

const getTabPoints = async (code, lng, category_id, terminal, memoized, signal) => {
   const key = `${code}${lng}${category_id}${terminal}`
   let points = memoized[key]

   if (!points) {
      const response = await fetch(
         // eslint-disable-next-line
         `${backendUrl}/points?airport_code=${code}&language_code=${lng}&products_category=${category_id}&terminal=${terminal}`,
         {
            signal
         }
      )
      const result = await response.json()
      points = result.data.points
      memoized[key] = points
   }

   return points
}

export const fetchTabs = createAsyncThunk(
   'tabs/fetchTabs',
   async ({ tabs, lng }, { signal }) => {
      const memoized = {}
      const data = {}

      for (const tab of tabs) {
         data[tab.tabName] = {}
         for (const category of tab.categories) {
            const points = await getTabPoints(tab.airport, lng, category, tab?.terminal || '', memoized, signal)
            data[tab.tabName][String(category)] = points
         }
      }

      return data
   }
)

export const setSelectedPoint = createAsyncThunk(
   'tabs/setSelectedPoint',
   async ({ point_id, lng }, { signal }) => {
      const response = await fetch(
         `${backendUrl}/v2/point?point_id=${point_id}&language_code=${lng}`,
         {
            signal
         }
      )
      const result = await response.json()

      const { point } = result.data

      point.products = []
      point?.act_types.forEach(
         act_type => act_type?.groups.forEach(
            group => group?.products?.forEach(
               product => point.products.push({ ...product, quantity: 0 })
            )))

      return point
   }
)

export const fetchToBucket = createAsyncThunk(
   'tabs/fetchToBucket',
   async ({ airport_id, date, items, custom_info }, { signal }) => {
      await fetch(
         `${backendUrl}/userCart/itemGroup`,
         {
            method: 'POST',
            credentials: 'include',
            body: JSON.stringify({
               items,
               airport_id,
               estimated_date: date,
               custom_info
            }),
            signal
         }
      )
   }
)

const _deleteTab = (state, tab) => {
   const tabs = state.tabs.filter(t => t.tabName !== tab.tabName)
   const selectedTab = state.selectedTab.tabName !== tab.tabName
      ? state.selectedTab
      : tabs.length > 0 ? tabs[0] : null
   return {
      ...state,
      tabs,
      selectedTab
   }
}

const _deleteTabCategory = (state, tab, category) => {
   let tabs = Object.entries(state.tabsPoints[tab.tabName]).reduce((acc, [k, v]) => {
      if (k !== category) acc[k] = v
      return acc
   }, {})

   if (Object.keys(tabs).length === 0) {
      return _deleteTab(state, tab)
   } else {
      state.tabsPoints[tab.tabName] = tabs
   }
}

const _deleteTabPoint= (state, tab, category, point) => {
   const points = state.tabsPoints[tab.tabName][category].filter(p => p.id != point.id)
   if (points.length === 0) {
      return _deleteTabCategory(state, tab, category)
   } else {
      state.tabsPoints[tab.tabName][category] = points
   }
}

const tabsSlice = createSlice({
   name: 'tabs',
   initialState,
   reducers: {
      configureTabs: (state, { payload }) => {
         const data = []

         if (payload.selectedServices.from.dateFrom.length) {
            data.push({
               tabName: 'from-from',
               ...payload.search.from,
               date: payload.search.dateFrom,
               categories: payload.selectedServices.from.dateFrom
            })
         }

         if (payload.selectedServices.to.dateFrom.length) {
            data.push({
               tabName: 'to-from',
               ...payload.search.to,
               date: payload.search.dateFrom,
               categories: payload.selectedServices.to.dateFrom
            })
         }

         if (payload.selectedServices.to.dateTo.length) {
            data.push({
               tabName: 'to-to',
               ...payload.search.to,
               date: payload.search.dateTo,
               categories: payload.selectedServices.to.dateTo
            })
         }

         if (payload.selectedServices.from.dateTo.length) {
            data.push({
               tabName: 'from-to',
               ...payload.search.from,
               date: payload.search.dateTo,
               categories: payload.selectedServices.from.dateTo
            })
         }

         return {
            ...state,
            tabs: data,
            selectedTab: data.length > 0 ? data.find(t => t.tabName === state.selectedTab?.tabName) || data[0] : null
         }
      },

      incProductQuantity: (state, { payload }) => {
         const index = state.selectedPoint.products.findIndex(p => p.id === payload.id)
         if (index !== -1) state.selectedPoint.products[index].quantity++
         return state
      },

      decProductQuantity: (state, { payload }) => {
         const index = state.selectedPoint.products.findIndex(p => p.id === payload.id)
         if (index !== -1) state.selectedPoint.products[index].quantity--
         return state
      },

      removeSelectedPoint: (state) => ({
         ...state,
         selectedPoint: null
      }),

      setSelectedTab: (state, { payload }) => ({
         ...state,
         selectedTab: payload
      }),

      deleteTabPoint: (state, { payload }) => {
         const {tab, category, point} = payload
         return _deleteTabPoint(state, tab, category, point)
      },

      deleteTabCategory: (state, { payload }) => {
         const {tab, category} = payload
         return _deleteTabCategory(state, tab, category)
      },

      deleteTab: (state, { payload }) => {
         return _deleteTab(state, payload)
      }
   },

   extraReducers: {
      [fetchTabs.fulfilled]: (state, { payload }) => ({
         ...state,
         tabsPoints: payload,
         isLoading: false
      }),
      [fetchTabs.pending]: (state) => ({
         ...state,
         tabsPoints: {},
         isLoading: true
      }),

      [setSelectedPoint.fulfilled]: (state, { payload }) => ({
         ...state,
         selectedPoint: payload
      }),
      [setSelectedPoint.pending]: (state) => ({
         ...state,
         selectedPoint: null
      }),

      [fetchToBucket.fulfilled]: (state) => ({
         ...state,
         selectedPoint: null
      })
   }
})

export const {
   removeSelectedPoint,
   incProductQuantity,
   decProductQuantity,
   setSelectedTab,
   deleteTab,
   deleteTabPoint,
   deleteTabCategory,
   configureTabs
} = tabsSlice.actions

export default tabsSlice.reducer
