import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'

i18n
   .use(Backend)
   .use(LanguageDetector)
   .use(initReactI18next)
   .init({
      debug: false,
      fallbackLng: false,
      cleanCode: false,
      load: 'all',
      supportedLngs: ['en', 'ru'],
      autoReload: true,

      backend: {
         loadPath: '/locales/{{lng}}/{{ns}}.json',
         addPath: '/locales/add/{{lng}}/{{ns}}',
         reloadInterval: 100
      },

      interpolation: {
         escapeValue: false
      }
   })

export default i18n
