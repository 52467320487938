import logo from '../../assets/images/logo.svg'
import { Link, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

export default function Navbar () {
   const user = useSelector((state) => state.user)
   const { cart } = useSelector((state) => state.cart)
   const location = useLocation()

   const { t, i18n } = useTranslation()

   return (
      <header>
         <div className='container'>
            <div className='navbar'>
               <div className='logo'>
                  <Link to='/'>
                     <img alt="" src={logo} />
                  </Link>
               </div>

               <div className='main_menu'>
                  <ul>
                     <li>
                        <Link
                           to='/'
                           className={location.pathname === '/' ? 'active' : ''}
                        >
                           {t('navbar.links.main')}
                        </Link>
                     </li>

                     <li>
                        <Link
                           to='/service'
                           className={location.pathname === '/service' ? 'active' : ''}
                        >
                           {t('navbar.links.services')}
                        </Link>
                     </li>

                     <li>
                        <Link
                           to='/partners'
                           className={location.pathname === '/partners' ? 'active' : ''}
                        >
                           {t('navbar.links.partners')}
                        </Link>
                     </li>
                  </ul>
               </div>

               <div className='sub_menu'>
                  <ul>
                     <li>
                        <a
                           onClick={(ev) => {
                              ev.preventDefault()
                              i18n.language === 'en'
                                 ? i18n.changeLanguage('ru')
                                 : i18n.changeLanguage('en')
                           }}
                           href="/"
                        >

                           <i className='language'></i>

                           {t('navbar.links.lngchange')}
                        </a>
                     </li>

                     <li>
                        <Link to='/profile'>
                           {(user.login.isLogined && !user.login.needFillProfile)
                              ? (
                                 <div className='logined'>
                                    {user?.user?.first_name[0] + user?.user?.last_name[0]}
                                 </div>
                              )
                              : (
                                 <i className='profileIcon'></i>
                              )}
                           {t('navbar.links.profile')}
                        </Link>
                     </li>

                     <li>
                        <Link to='/cart'>
                           <div
                              className={
                                 cart && cart?.groups?.length
                                    ? 'counter'
                                    : 'counter disabled'
                              }
                           >
                              {cart && cart?.groups?.length}
                           </div>

                           <i className='cart'></i>

                           {t('navbar.links.cart')}
                        </Link>
                     </li>
                  </ul>
               </div>
            </div>
         </div>
      </header>
   )
}
