import { useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'

import SelectItemList from '../select-item/SelectItemList'
import { setAirportFrom, setAirportTo } from '../../../redux/slices/searchSlice'

export default function SelectGroup ({ direction }) {
   const { t } = useTranslation()
   const dispatch = useDispatch()

   const search = useSelector((state) => state.search)
   const airports = useSelector(state => state.airports)
   const findAirport = code => airports.find(a => a.code_iata === code) || {}

   const [value, setValue] = useState('')
   const [isOpen, setIsOpen] = useState(false)

   const selectListRef = useRef(null)
   const handleKeyDown = (e) => {
      if ([38, 40, 13].includes(e.keyCode)) e.preventDefault()
      if (e.keyCode === 38) selectListRef.current.cursorUp()
      else if (e.keyCode === 40) selectListRef.current.cursorDown()
      else if (e.keyCode === 13) selectListRef.current.setAirport()
   }

   const inputAirport = direction === 'from'
      ? () => { if (search.from.airport) dispatch(setAirportFrom({ airport: null, terminal: null })) }
      : () => { if (search.to.airport) dispatch(setAirportTo({ airport: null, terminal: null })) }
   const input = (e) => {
      setIsOpen(true)
      setValue(e.target.value)
      inputAirport()
   }

   useEffect(() => (!!search.from.airport || !!search.to.airport) && setIsOpen(false), [search.from, search.to])

   const closeSelect = () => setIsOpen(false)
   useEffect(() => {
      if (isOpen) {
         window.addEventListener('click', closeSelect)
      } else {
         window.removeEventListener('click', closeSelect)
      }

      return () => {
         window.removeEventListener('click', closeSelect)
      }
   }, [isOpen])

   return (
      <div
         className={direction + (search[direction].airport ? ' set' : '') + (isOpen ? ' focused' : '')}
         onClick={() => setIsOpen(!isOpen)}
      >

         {direction === 'from'
            ? (
               <input
                  value={ search?.from?.airport ? findAirport(search.from.airport)?.verbalName : value }
                  onKeyDown={handleKeyDown}
                  type="text"
                  onChange={input}
                  placeholder={t('searchbar.city_from')}
                  className={'search-input' + (search[direction].airport ? ' set' : '') + (isOpen ? ' focused' : '')}
               />)
            : (
               <input
                  value={ search?.to?.airport ? findAirport(search.to.airport)?.verbalName : value }
                  onKeyDown={handleKeyDown}
                  type="text"
                  onChange={input}
                  placeholder={t('searchbar.city_to')}
                  className={'search-input' + (search[direction].airport ? ' set' : '') + (isOpen ? ' focused' : '')}
               />
            )
         }

         {isOpen && <div className="select open">
            <SelectItemList
               direction={direction}
               value={value}
               setValue={setValue}
               ref={selectListRef}
            />
         </div>}
      </div>
   )
}
