import { Carousel } from 'react-carousel-minimal'
import { useTranslation } from 'react-i18next'

import img1 from '../../assets/images/owl/pic_01.jpg'
import img2 from '../../assets/images/owl/pic_02.jpg'
import img3 from '../../assets/images/owl/pic_03.jpg'

import './carousel.scss'

export default function HeaderCarousel () {
   const { t } = useTranslation()
   const carouselData = [
      {
         image: img1,
         caption: t('carousel.caption')
      },
      {
         image: img2,
         caption: t('carousel.caption')
      },
      {
         image: img3,
         caption: t('carousel.caption')
      }
   ]

   return (
      <section className='main-slider'>
         <Carousel
            automatic={true}
            time={4000}
            width='100%'
            height='380px'
            data={carouselData}
            captionPosition='center'
            dots={false}
            slideBackgroundColor='darkgrey'
            slideImageFit='cover'
         />
      </section>
   )
}
