import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { backendUrl } from '../../constants'

const initialState = {
   cards: [],
   types: [],
   transactions: [],
   isLoading: false
}

export const getPrivilegesTypes = createAsyncThunk(
   'privileges/getPrivilegesTypes',
   async (_, { signal, rejectWithValue }) => {
      const response = await fetch(
         `${backendUrl}/bankPrivileges/types`,
         {
            method: 'GET',
            credentials: 'include',
            signal
         }
      )
      const data = await response.json()
      if (data.responseCode === 22) return rejectWithValue(22)
      else return data
   }
)

export const getPrivilegesCards = createAsyncThunk(
   'privileges/getPrivilegesCards',
   async (_, { signal, rejectWithValue }) => {
      const response = await fetch(
         `${backendUrl}/bankPrivileges/cards`,
         {
            method: 'GET',
            credentials: 'include',
            signal
         }
      )
      const data = await response.json()
      if (data.responseCode === 22) return rejectWithValue(22)
      else return data
   }
)

export const getPrivilegesTransactions = createAsyncThunk(
   'privileges/getPrivilegesTransactions',
   async (_, { signal }) => {
      const response = await fetch(
         `${backendUrl}/bankPrivileges/transactions`,
         {
            method: 'GET',
            credentials: 'include',
            signal
         }
      )
      const data = await response.json()
      return data?.data?.transactions
   }
)

const privilegesSlice = createSlice({
   name: 'privileges',
   initialState,
   reducers: {},
   extraReducers: {
      [getPrivilegesCards.fulfilled]: (state, { payload }) => {
         state.cards = payload?.data?.cards
         state.isLoading = false
      },
      [getPrivilegesCards.pending]: (state) => {
         state.isLoading = true
      },

      [getPrivilegesTypes.fulfilled]: (state, { payload }) => {
         state.types = payload?.data?.types
         state.isLoading = false
      },
      [getPrivilegesTypes.pending]: (state) => {
         state.isLoading = true
      },

      [getPrivilegesTransactions.fulfilled]: (state, { payload }) => {
         state.transactions = payload
         state.isLoading = false
      },
      [getPrivilegesTransactions.pending]: (state) => {
         state.isLoading = true
      }
   }
})

export default privilegesSlice.reducer
