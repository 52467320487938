import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { backendUrl } from '../../constants'

export const getAirportString = (airport, terminal) => {
   if (!airport) return ''
   return `${airport?.city || ''}, ${airport?.title || ''}` + (terminal ? `, ${terminal.title}` : '')
}

export const fetchAirportsList = createAsyncThunk(
   'airportsSlice/fetchAirportsList',
   async (lng, { signal }) => {
      const response = await fetch(
         `${backendUrl}/airports?language_code=${lng}`,
         {
            signal
         }
      )
      const result = await response.json()
      const airports = result.data
      return airports
   }
)

const airportsSlice = createSlice({
   name: 'airports',
   initialState: [],
   reducers: {},
   extraReducers: {
      [fetchAirportsList.fulfilled]: (_, { payload }) => payload.map(item =>
         item.terminals?.length > 0
            ? item.terminals.map(terminal => ({
               ...item,
               id: terminal.id,
               verbalName: getAirportString(item, terminal),
               terminal
            }))
            : {
               ...item,
               verbalName: getAirportString(item, null)
            }).flat()
   }
})

export default airportsSlice.reducer
