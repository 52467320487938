import { RouteProps } from 'react-router-dom'
import Home from '../pages/home/Home'
import { Cart, 
   FillProfile, 
   Login, 
   NotFound, 
   Partners, 
   Points, 
   Profile, 
   Service, 
   Services, 
   Success, 
   WithoutRegistration 
} from '../pages'

export enum AppRoutes {
   MAIN = 'main',
   LOGIN = 'login',
   LOGIN_WITHOUT = 'login_without',
   PROFILE = 'profile',
   PARTNERS = 'partners',
   FILL_PROFILE = 'fill_profile',
   SERVICES = 'services',
   SERVICE = 'service',
   POINTS = 'points',
   POINT = 'point',
   CART = 'cart',
   SUCCESS = 'success',
   NOT_FOUND = 'not_found'
}

export const RoutePath: Record<AppRoutes, string> = {
   [AppRoutes.MAIN]: '/',
   [AppRoutes.LOGIN]: '/login',
   [AppRoutes.LOGIN_WITHOUT]: '/login/without',
   [AppRoutes.PROFILE]: '/profile',
   [AppRoutes.PARTNERS]: '/partners',
   [AppRoutes.FILL_PROFILE]: '/fillProfile',
   [AppRoutes.SERVICES]: '/services',
   [AppRoutes.SERVICE]: '/service',
   [AppRoutes.POINTS]: '/points',
   [AppRoutes.POINT]: '/points/',
   [AppRoutes.CART]: '/cart',
   [AppRoutes.SUCCESS]: '/success',
   [AppRoutes.NOT_FOUND]: '*'
}

export const routeConfig: Record<AppRoutes, RouteProps> = {
   [AppRoutes.MAIN]: {
      path: RoutePath.main,
      element: <Home />
   },
   [AppRoutes.LOGIN]: {
      path: RoutePath.login,
      element: <Login />
   },
   [AppRoutes.LOGIN_WITHOUT]: {
      path: RoutePath.login_without,
      element: <WithoutRegistration />
   },
   [AppRoutes.PROFILE]: {
      path: RoutePath.profile,
      element: <Profile />
   },
   [AppRoutes.PARTNERS]: {
      path: RoutePath.partners,
      element: <Partners />
   },
   [AppRoutes.FILL_PROFILE]: {
      path: RoutePath.fill_profile,
      element: <FillProfile/>
   },
   [AppRoutes.SERVICES]: {
      path: RoutePath.services,
      element: <Services/>
   },
   [AppRoutes.SERVICE]: {
      path: RoutePath.service,
      element: <Service/>
   },
   [AppRoutes.POINTS]: {
      path: RoutePath.points,
      element: <Points/>
   },
   [AppRoutes.POINT]: {
      path: `${RoutePath.point}:pointId`,
      element: <Points />
   },
   [AppRoutes.CART]: {
      path: RoutePath.cart,
      element: <Cart/>
   },
   [AppRoutes.SUCCESS]: {
      path: RoutePath.success,
      element: <Success/>
   },
   [AppRoutes.NOT_FOUND]: {
      path: RoutePath.not_found,
      element: <NotFound/>
   },
}
